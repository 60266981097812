* {
  font-family: "Soehne";
}

/* font-thin */
@font-face {
  font-family: "Soehne";
  src: url("./Fonts/soehne/soehne-web-extraleicht.woff2") format("woff2"),
    url("./Fonts/soehne/soehne-web-extraleicht.woff") format("woff");
  font-weight: 100;
}

/* font-extralight */
@font-face {
  font-family: "Soehne";
  src: url("./Fonts/soehne/soehne-web-leicht.woff2") format("woff2"),
    url("./Fonts/soehne/soehne-web-leicht.woff") format("woff");
  font-weight: 200;
}

/* font-light */
@font-face {
  font-family: "Soehne";
  src: url("./Fonts/soehne/soehne-web-buch.woff2") format("woff2"),
    url("./Fonts/soehne/soehne-web-buch.woff") format("woff");
  font-weight: 300;
}

/* font-normal */
@font-face {
  font-family: "Soehne";
  src: url("./Fonts/soehne/soehne-web-kraftig.woff2") format("woff2"),
    url("./Fonts/soehne/soehne-web-kraftig.woff") format("woff");
  font-weight: 400;
}

/* font-medium */
@font-face {
  font-family: "Soehne";
  src: url("./Fonts/soehne/soehne-web-halbfett.woff2") format("woff2"),
    url("./Fonts/soehne/soehne-web-halbfett.woff") format("woff");
  font-weight: 500;
}

/* font-semibold */
@font-face {
  font-family: "Soehne";
  src: url("./Fonts/soehne/soehne-web-dreiviertelfett.woff2") format("woff2"),
    url("./Fonts/soehne/soehne-web-dreiviertelfett.woff") format("woff");
  font-weight: 600;
}

/* font-bold */
@font-face {
  font-family: "Soehne";
  src: url("./Fonts/soehne/soehne-web-fett.woff2") format("woff2"),
    url("./Fonts/soehne/soehne-web-fett.woff") format("woff");
  font-weight: 700;
}

/* font-extrabold */
@font-face {
  font-family: "Soehne";
  src: url("./Fonts/soehne/soehne-web-extrafett.woff2") format("woff2"),
    url("./Fonts/soehne/soehne-web-extrafett.woff") format("woff");
  font-weight: 800;
}
