/* Reach Listbox isn't fully compatible with Tailwind, so we use custom CSS rather than tailwind classes */

.org-selector [data-reach-listbox-button] {
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  color: #072a57;
  font-weight: 600;
}

.org-selector[data-state="expanded"] [data-reach-listbox-button] {
  color: white;
}

.org-selector [data-reach-listbox-popover] {
  left: 0;
  width: 360px;
  background-color: #efefef;
  padding: 0;
  margin-top: 25px;
}

.org-selector [data-reach-listbox-option] {
  color: #1a2e61;
  font-weight: 300;
  font-size: 19px;
  line-height: 26px;
  padding: 0;
}

.org-selector [data-current-nav] {
  background-color: #cccccc;
}

.org-selector-parent:has(> .org-selector[data-state="expanded"]) {
  background-color: #1a2e61;
  color: white;
}

.org-selector-parent .border-consoleGray-200 {
  border: none;
}
